export const dashboard = {
  slogan: 'Selamat datang ke Maybank2u',
  container: {
    title: 'Selamat! Anda telah berhasil mendaftar',
  },
  maintenance: 'Saat ini kami sedang menjalani pemeliharaan sistem.',
  tryAgain: 'Sistem tidak dapat memproses permintaan ini untuk sementara waktu. Silahkan coba lagi nanti.',
  ccError:
    '{clickHere} untuk mendapatkan fasilitas kartu kredit dari Maybank yang sesuai dengan kebutuhan Anda dan nikmati berbagai promo menariknya',
  header: {
    lastLogin: 'Login terakhir Anda pada {timestamp}',
    topNavigation: {
      digitalWealth: 'My Portfolio 360',
      myAccount: 'Rekening Saya',
      transaction: 'Transaksi',
      apply: 'Daftar',
    },
  },
  sidebar: {
    topNavigation: {
      inbox: 'Kotak Masuk',
      settings: 'Pengaturan',
      logout: 'Keluar',
    },
    donation: {
      linkLabel: 'Lihat Total Donasi',
    },
    quickTransfer: {
      title: 'Pembayaran & Transfer Cepat',
    },
    footer: {
      tutorial: 'TUTORIAL',
      term: 'SYARAT DAN KETENTUAN',
      privacy: 'PEMBERITAHUAN PRIVASI',
      sec: 'PERNYATAAN KEAMANAN',
      fees: 'LIMIT DAN BIAYA',
    },
    link: {
      term: 'https://www.maybank.co.id/id/ebanking/syarat-dan-ketentuan-maybank2u',
      privacy: 'https://www.maybank.co.id/id/securityprivacypolicy',
      sec: 'https://www.maybank.co.id/id/securityprivacypolicy?categoryId=%7b11C73F5C08214313B9985274B2894D52%7d',
      fees: 'https://www.maybank.co.id/id/ebanking/feeandcharge',
    },
  },
  casa: {
    label: 'Rekening',
    availableBalance: 'Saldo Tersedia',
    balanceIDR: 'Saldo dalam IDR',
    equivalent: 'Setara {equivalentAmount}',
    currency: '{currencyAmount} Mata Uang',
    branch: 'Cabang',
    initialBalance: 'Saldo Awal',
    beginningBalance: 'Saldo awal',
    holdBalance: 'Saldo Diblokir',
    addAccount: 'Tambah Rekening',
    noAccount: 'Anda tidak memiliki rekening bersama kami. ',
    balance: 'Saldo',
    menu: {
      viewDetails: 'Lihat Detail',
      addAccount: 'Tambah Rekening',
      payment: 'Pembayaran',
      transfer: 'Transfer',
      purchase: 'Pembelian',
      addBalance: 'Tambah Dana',
    },
    details: {
      date: 'Tanggal',
      description: 'Deskripsi',
      amount: 'Jumlah',
      today: 'Hari ini',
      download: 'Unduh',
    },
    transactionHistory: {
      today: 'Transaksi Hari Ini',
      thisMonth: 'Transaksi Bulan Ini',
      previousMonth: 'Transaksi Bulan Sebelumnya',
      noTransactionHistory: 'Tidak Ada Riwayat Transaksi',
      allTransactionDetails: 'Seluruh Transaksi',
      m2u: 'Transaksi M2U',
    },
    notification: {
      title: 'Notifikasi',
      content: 'Unduh Selesai!',
    },
  },
  td: {
    leaveAlert: {
      title: 'Keluar dari Halaman Deposito',
      description:
        'Apakah Anda yakin untuk meninggalkan halaman ini? Klik "Batal" untuk tetap di sini atau klik "OK" untuk meninggalkan halaman ini.',
    },
    label: 'Deposito',
    totalAmount: 'Jumlah Total',
    totalAccount: 'Total Rekening',
    maturityDate: 'Tanggal Jatuh Tempo: ',
    noAccount: 'Anda tidak memiliki produk Deposito.',
    menu: {
      viewDetail: 'Lihat Detail',
      stopARO: 'Stop Perpanjangan Otomatis',
    },
    confirmTac: {
      akad: 'Akad',
      amountLabel: 'Jumlah',
      depositAccountNumber: 'Nomor Rekening Deposito',
      from: 'Rekening asal',
      initialDepositTitle: 'Penempatan Deposito',
      instructionAtMaturity: 'Instruksi Jatuh Tempo',
      interestRate: 'Suku Bunga',
      interestRateIB: 'Indikasi Imbal Hasil (%p.a)',
      referenceNo: 'Nomor Referensi',
      resultTitleFail: 'Transaksi Gagal!',
      resultTitleSuccess: 'Transaksi Berhasil!',
      successNotif: 'Deposito Baru Berhasil Ditambahkan',
      tacTitle: 'Silakan mengkonfirmasi detail instruksi transaksi Anda.',
      tacDescription: 'Untuk melanjutkan, silakan mengajukan permintaan TAC dan masukkan nomor <b>TAC</b> Anda.',
      term: 'Jangka Waktu',
      zakat: 'Zakat (2.5% dari Imbal Hasil)',
    },
    products: {
      description: 'Buka Deposito Online Sekarang dan Dapatkan Bunga up to 5.00%',
      descriptionIB: 'Segera buka Deposito iB Online dengan proyeksi imbal hasil hingga 5.00%',
    },
    errorMessage: {
      invalidPromoCode: 'Kode Promo tidak valid',
      invalidReferralCode: 'Kode Referral tidak valid',
      maxAmount: 'Nilai yang Anda masukkan melebihi batas maksimum {amount}',
      minAmount: 'Nilai yang Anda masukkan kurang dari batas minimum {amount}',
      required: 'Ruang ini tidak boleh kosong',
    },
    features: {
      accountDetailNotesTitle: 'Catatan:',
      accountDetailNotesContent:
        '<ul style="list-style-type:disc;margin:0;padding:0;"><li>Minimum penempatan deposito adalah IDR 10.000.000 atau USD 2.000</li><li>Penempatan / Pencairan deposito pada hari sabtu, minggu atau hari libur nasional akan diproses pada hari kerja berikutnya.</li><li>Penghentian perpanjangan otomatis Deposito dengan instruksi Principal Roll Over dan Principal + Interest Roll Over hanya dapat dilakukan via Maybank2U melalui fitur Stop Auto Roll Over yang ada di halaman detail deposito, selambat-lambatnya H-2 hari kerja sebelum tanggal jatuh tempo. Apabila Stop Auto Roll Over dilakukan kurang dari H-2 hari kerja maka tanggal jatuh tempo akan berlaku efektif pada periode jatuh tempo berikutnya. Pokok dan Bunga akan di transfer ke rekening sumber dana pada tanggal jatuh tempo.</li><li>Pastikan bahwa Instruksi Pada Saat Jatuh Tempo telah berubah menjadi Jatuh Tempo pada saat melakukan Stop Auto Roll Over</li><li>Pencairan Deposito hanya bisa dilakukan pada saat jatuh tempo.</li></ul>',
      accountDetailNotesContentIB:
        '<ul style="list-style-type:disc;margin:0;padding:0;"><li>Minimum penempatan Deposito iB adalah IDR 10.000.000 atau USD 2.000</li><li>Penempatan / Pencairan Deposito iB pada hari sabtu, minggu atau hari libur nasional akan diproses pada hari kerja berikutnya.</li><li>Penghentian perpanjangan otomatis Deposito iB dengan instruksi Principal Roll Over dan Principal + Interest Roll Over hanya dapat dilakukan via Maybank2U melalui fitur Stop Auto Roll Over yang ada di halaman detail deposito, selambat-lambatnya H-2 hari kerja sebelum tanggal jatuh tempo. Apabila Stop Auto Roll Over dilakukan kurang dari H-2 hari kerja maka tanggal jatuh tempo akan berlaku efektif pada periode jatuh tempo berikutnya. Pokok dan Bunga akan di transfer ke rekening sumber dana pada tanggal jatuh tempo.</li><li>Pastikan bahwa Instruksi Pada Saat Jatuh Tempo telah berubah menjadi Jatuh Tempo pada saat melakukan Stop Auto Roll Over</li><li>Pencairan Deposito iB hanya bisa dilakukan pada saat jatuh tempo.</li></ul>',
      accountDetailTitle: 'Detail Rekening',
      head: '<b>Maybank Deposito</b>',
      headIB: '<b>Maybank Deposito iB:</b><br/>Simpanan berjangka dengan akad Mudharabah',
      benefitsTitle: '<b>Manfaat:</b>',
      featureTitle: '<b>Fitur</b>',
      form: {
        sof: 'Dari Rekening',
        term: 'Tenor',
        amount: 'Jumlah',
        instructionAtMaturity: 'Instruksi Jatuh Tempo',
        instructionAtMaturityValue1: 'Perpanjang Pokok',
        instructionAtMaturityValue2: 'Perpanjang Pokok + Bunga',
        instructionAtMaturityValue3: 'Tidak diperpanjang',
        akad: 'Akad',
        akadValue1: 'Mudharabah',
        checkboxReferral: 'Mohon centang disini untuk mengisikan Kode Referral',
        referralAndPromoCode: 'Kode Referral & Promo',
        promoCode: 'Kode Promo (opsional)',
        referralCode: 'Kode Referral (opsional)',
        zakat: 'Zakat (2.5% dari Imbal Hasil)',
      },
      benefitsContent:
        '<li>Suku bunga kompetitif.</li><li>Tersedia dalam mata uang Rupiah & USD.</li><li>Fasilitas perpanjangan otomatis.</li>',
      benefitsContentIB:
        '<li>Bagi hasil kompetitif.</li><li>Tersedia dalam mata uang Rupiah & USD.</li><li>Fasilitas perpanjangan otomatis.</li><li>Opsi untuk otomatis membayar zakat dari imbal hasil yang didapat.</li>',
      checklistLabel:
        '<b>Dengan ini,</b><ol style="margin: 0;padding-left:1em;"><li style="font-weight:normal;">Saya menyatakan bahwa saya bukan wajib pajak diluar Indonesia maupun wajib pajak Amerika Serikat ATAU dalam hal saya pernah menyampaikan Formulir Penyataan Diri terkait FATCA/CRS saya sebelumnya kepada pihak Bank dan atau tidak ada perubahan terkait data tersebut. Saya berjanji akan memberitahukan ke Bank secara tertulis selambat-lambatnya 90 hari kalender dalam hal terdapat perubahan keadaan atau informasi termasuk tetapi tidak terbatas atas status pajak saya atau informasi wajib lainnya yang menyebabkan pernyataan FATCA/CRS saya ini menjadi tidak benar atau tidak lengkap</li><li>Saya telah membaca dan memahami fitur dari produk ini</li><li style="font-weight:normal;">Saya menyatakan bahwa saya telah membaca dan setuju dengan syarat dan ketentuan yang berlaku</li></ol>',
      checklistLabelIB:
        '<b>Dengan ini,</b><ol style="margin: 0;padding-left:1em;"><li style="font-weight:normal;">Saya menyatakan bahwa saya bukan wajib pajak diluar Indonesia maupun wajib pajak Amerika Serikat ATAU dalam hal saya pernah menyampaikan Formulir Penyataan Diri terkait FATCA/CRS saya sebelumnya kepada pihak Bank dan atau tidak ada perubahan terkait data tersebut. Saya berjanji akan memberitahukan ke Bank secara tertulis selambat-lambatnya 90 hari kalender dalam hal terdapat perubahan keadaan atau informasi termasuk tetapi tidak terbatas atas status pajak saya atau informasi wajib lainnya yang menyebabkan pernyataan FATCA/CRS saya ini menjadi tidak benar atau tidak lengkap</li><li>Saya telah membaca dan memahami fitur dari produk ini</li><li style="font-weight:normal;">Saya menyatakan bahwa saya telah membaca dan setuju dengan syarat dan ketentuan serta Akad Deposito iB yang berlaku</li><li style="font-weight:normal;">Saya telah memahami bahwa syarat dan ketentuan yang tercantum ini merupakan satu kesatuan dengan Akad Mudharabah Deposito iB</li></ol>',
      downloadTermandCondition: 'Unduh Syarat dan Ketentuan',
      downloadAkadMudharabah: 'Unduh Akad Mudharabah',
      interestRateTitle: '<b>Suku Bunga (%p.a)</b>',
      interestRateTitleIB: '<b>Indikasi Imbal Hasil (%p.a)</b>',
      interestRateTableHeaders: {
        placement: 'Nominal Penempatan',
        value1: '1 Bulan',
        value2: '3 Bulan',
        value3: '6 Bulan',
        value4: '12 Bulan',
      },
      minimumPlacementTitle: '<b>Minimum Penempatan</b>',
      minimumPlacementContent: '<li>IDR: 10.000.000</li><li>USD: 2.000</li>',
      noInterestRate: 'Tidak ada suku Bunga.',
      noInterestRateIB: 'Tidak ada Indikasi Imbal Hasil.',
      productDetailTitle: 'Detail Produk',
      tncTitle: '<b>Ketentuan:</b>',
      tncTitle2: '<b>Syarat dan Ketentuan</b>',
      tncContent:
        '<li>Maybank Deposito hanya dapat dicairkan pada saat jatuh tempo.</li><li>Jika nasabah memilih perpanjangan otomatis Maybank Deposito, maka penghentian perpanjangan tersebut hanya dapat dilakukan melalui M2U ID App/M2U ID Web untuk selanjutnya nilai pokok dan bunga akan dikreditkan ke rekening sumber pada tanggal jatuh tempo.</li>',
      tncContentIB:
        '<li>Maybank Deposito iB hanya dapat dicairkan pada saat jatuh tempo.</li><li>Jika Nasabah memilih perpanjangan otomatis Maybank Deposito iB, maka penghentian perpanjangan tersebut hanya dapat dilakukan melalui M2U ID App / M2U ID Web untuk selanjutnya nilai pokok dan bunga akan dikreditkan ke rekening sumber pada tanggal jatuh tempo.</li><li>Informasi tabel di bawah berlaku untuk pembukaan Maybank Deposito iB via M2U ID App/M2U ID Web.</li>',
      placementPeriodsTitle: '<b>Jangka Waktu Penempatan</b>',
      placementPeriodsContent: 'Mulai dari 1, 3, 6 & 12 bulan.',
    },
    details: {
      balance: 'Saldo',
      balanceIDR: 'Saldo Dalam IDR',
      accountNo: 'Nomor Rekening',
      principalAmount: 'Nilai Principal',
      holdAmount: 'Nilai Ditahan',
      interestRate: 'Suku Bunga',
      indicativeEquivalentRate: 'Indikasi Imbal Hasil',
      indicativeRateofReturn: 'Indikasi Imbal Hasil',
      term: 'Periode',
      placementDate: 'Tanggal Pembukaan',
      maturityDate: 'Tanggal Jatuh Tempo',
      nextPaymentDate: 'Tanggal Pembayaran Bunga Berikutnya',
      nextIndicativeEquivalentRatePaymentDate: 'Tanggal Pembayaran Indikasi Imbal Hasil',
      uponMaturity: 'Instruksi Jatuh Tempo',
      accruedInterest: 'Nilai Bunga Berjalan',
      accruedIndicativeEquivalentRate: 'Indikasi Imbal Hasil Berjalan',
      branch: 'Cabang',
      transactionDate: 'Tanggal Transaksi',
      refId: 'Ref ID',
      profitSharingRatio: 'Nisbah Bagi Hasil',
    },
    placeholder: {
      pleaseSelect: 'Silahkan Pilih',
    },
    stopAro: {
      tacTitle: 'Silakan mengkonfirmasi detail instruksi transaksi Anda.',
      tacDescription: 'Untuk melanjutkan, silakan mengajukan permintaan TAC dan masukkan nomor <b>TAC</b> Anda.',
      transaction: {
        successful: 'Permintaan Anda berhasil!',
        failed: 'Permintaan Anda gagal!',
      },
      notice:
        'Permintaan untuk menghentikan instruksi jatuh tempo "Automatic Rollover/Perpanjangan Otomatis" akan efektif dalam 2 hari kerja sejak diajukan ke Bank.',
    },
  },
  cc: {
    label: 'Kartu kredit',
    creditLimit: 'Limit Kredit',
    billingCycle: 'Siklus Penagihan',
    availableCredit: 'Kredit yang Tersedia',
    currentPayment: 'Pembayaran Terkini',
    ccHolderName: 'Nama Pemegang Kartu',
    expDate: 'Tanggal Berkahir',
    cashAdvancedLimit: 'Limit Tarik Tunai',
    dailyCashAdvancedLimit: 'Limit Cash Advance Harian',
    currentBalance: 'Saldo Terkini',
    outstandingAuthItem: 'Jumlah Outstanding Authorization',
    outstandingAuthAmount: 'Nominal Outstanding Authorization',
    availableCashAdvanced: 'Cash Advance tersedia sebanyak {date}',
    dailyCashAdvanced: 'Cash Advance harian sebanyak {date}',
    noAccount:
      'untuk mendapatkan fasilitas kartu kredit dari Maybank yang sesuai dengan kebutuhan Anda dan nikmati berbagai promo menariknya',
    billingAmount: 'Pembayaran Penuh',
    minAmount: 'Pembayaran Minimal',
    otherAmount: 'Jumlah Lainnya',
    currentUsage: 'Pemakaian Terkini',
    due: 'jatuh tempo',
    menu: {
      viewDetail: 'Lihat Detail',
      payCard: 'Bayar Kartu Kredit',
      blockCard: 'Blokir Kartu',
    },
    details: {
      date: 'Tanggal',
      description: 'Deskripsi',
      amount: 'Jumlah',
    },
    transactionHistory: {
      recentTransaction: 'Transaksi Terkini',
      lastStatement: 'Tagihan Terakhir',
      previousStatement: 'Pernyataan Sebelumnya',
      noTransactionHistory: 'Tidak Ada Riwayat Transaksi',
      allTransactionHistory: 'Semua Riwayat Transaksi',
    },
    link: {
      clickHere: 'Klik disini',
      ccReg: 'https://www.maybank.co.id/creditcard',
    },
  },
  loan: {
    label: 'Pinjaman/Pembiayaan',
    totalLoan: 'Total Sisa Pinjaman/Pembiayaan',
    accountType: 'Tipe Rekening',
    currency: 'Mata Uang',
    noAccount:
      'untuk mendapatkan fasilitas pembiayaan dari Maybank yang sesuai dengan kebutuhan Anda dan nikmati berbagai promo menariknya',
    branch: 'Cabang',
    marginRate: 'Marjin saat ini',
    rollOver: 'Rollover Berikutnya',
    noteNumber: 'Nomor Nota',
    delayPeriod: 'Periode Keterlambatan',
    principalDueAmount: 'Nominal Pokok',
    principalDueDate: 'Tanggal Nominal Pokok ',
    productType: 'Jenis Produk',
    monthlyInstallment: 'Cicilan Bulanan',
    installmentOverdue: 'Nominal Angsuran Terlambat',
    defaultInterest: 'Bunga Terlambat Default',
    remainingOutstanding: 'Total Pokok Pinjaman/Pembiayaan',
    loanPeriod: 'Periode Pinjaman',
    lastInstallmentDate: 'Tanggal Angsuran Terakhir',
    lateChargeFee: 'Denda Keterlambatan',
    latePaymentCharge: 'Biaya Keterlambatan',
    currentInterestRate: 'Suku Bunga',
    indicativeRateofReturn: 'Indikasi Imbal Hasil',
    nextPaymentDue: 'Tanggal Jatuh Tempo Pembayaran Berikutnya: ',
    noPaymentHistory: 'Tidak Ada Sejarah Pembayaran',
    maturity: 'Tanggal Jatuh Tempo Kredit',
    menu: {
      viewDetail: 'Lihat Detail',
    },
    details: {
      interestRate: 'Suku Bunga',
      maturity: 'Tanggal Jatuh Tempo',
      currentBalance: 'Saldo margin terkini',
      totalBilled: 'Total pokok yang ditagih',
      outstanding: 'Pinjaman/Pembiayaan terutang',
      payoffAmount: 'Jumlah Pembayaran Pinjaman/Pembiayaan Per Hari Ini',
      tenor: 'Periode Pinjaman/Pembiayaan',
    },
  },
  wealth: {
    label: 'Investasi',
    product: 'Produk',
    totalValue: 'Total Saldo',
    totalProduct: 'Total Produk',
    gcif: 'GCIF',
    sid: 'SID',
    mutualFund: 'Reksa Dana',
    bonds: 'Obligasi',
    noAccount: 'Anda belum memiliki produk Investasi bersama kami. ',
    details: {
      productName: 'Nama Produk',
      policyHolderName: 'Nama pemegang polis',
      balanceUnit: 'Balance Unit',
      unit: 'Unit',
      navDate: 'Tanggal NAB',
      fundName: 'Nama Fund',
      fundManager: 'Nama Manajer Investasi',
      accountNo: 'Nomor Rekening',
      totalUnit: 'Unit Total',
      totalValue: 'Nilai Total',
      nav: 'NAB',
      productType: 'Tipe Produk',
      pendingSwitching: 'Pengalihan sedang diproses',
      growthFund: 'Pertumbuhan RD',
      balance: 'Jumlah',
      balanceAmountDisp: 'Nominal Kepemilikan',
      coupon: 'Kupon',
      maturityDate: 'Tanggal Jatuh Tempo',
      tenor: 'Tenor',
      policyNumber: 'Nomor Polis',
      currency: 'Mata Uang',
      outstandingUnit: 'Outstanding Unit',
      navAsOf: 'NAB per {date}',
      pendingSubscription: 'Pembelian sedang diproses',
      pendingRedemption: 'Penjualan sedang diproses',
      productCode: 'Kode Produk',
      couponPeriod: 'Periode Kupon',
      couponDate: 'Tanggal Kupon',
      startDateEarlyRedemption: 'Tanggal Mulai Early Redemption',
      endDateEarlyRedemption: 'Tanggal Akhir Early Redemption',
      settlementDate: 'Tanggal Settlement',
      gainLossPotency: 'Potensi Untung/Rugi',
      payeeName: 'Nama Penerima Pembayaran',
      policyHolder: 'Pemegang Polis',
      mtmAmountDisp: 'Nilai Investasi',
    },
    history: {
      nav: 'NAV',
      date: 'TANGGAL',
      unit: 'UNIT',
      price: 'HARGA',
      amount: 'JUMLAH',
      faceValue: 'NILAI NOMINAL',
      description: 'KETERANGAN',
      productName: 'NAMA PRODUK',
      transactionType: 'TIPE TRANSAKSI',
      day30: '30 Hari',
      day60: '60 Hari',
      day90: '90 Hari',
      noTransactionHistory: 'Tidak Ada Riwayat Transaksi',
    },
    disclaimer: {
      exchangeRate:
        'Nilai tukar valuta asing yang digunakan untuk menghitung Aset Anda ke dalam mata uang IDR/Rupiah merupakan nilai tukar indikatif dan dapat berubah sewaktu-waktu tanpa pemberitahuan sebelumnya.',
      detail:
        'Laporan Produk Reksadana, Obligasi Ritel dan/atau Bancassurance merupakan layanan tambahan dari Maybank dan tidak menggantikan dokumen apapun yang diterbitkan oleh pihak ketiga yang berwenang seperti Bank Kustodian maupun mitra penyedia produk untuk Maybank. Jika ada perbedaan antara informasi/nilai laporan dengan dokumen yang diterbitkanoleh pihak ketiga, Nasabah dapat mengacu ke dokumen yang diterbitkan oleh pihak ketiga yang berwenang sebagai dokumen yang sah.',
      feedback:
        'Anda dapat menyampaikan saran dan masukan dengan menghubungi Call Center 1500611 atau mengirimkan email ke <a href="mailto:customercare@maybank.co.id">customercare@maybank.co.id</a>',
    },
  },
  coachmark: {
    title: 'Tutorial',
    description: 'Selamat datang di M2U ID Web! Kami akan memandu Anda untuk memudahkan navigasi melalui M2U ID Web baru kami',
    checklist: 'Ingat pilihan saya',
  },
  sessionExpired: {
    title: 'Batas Waktu Sesi',
    description:
      'Sesi Anda akan berakhir dalam {counting}. {br} Klik OK untuk tetap berada di halaman M2U Web atau klik BATAL untuk logout.',
  },
};
